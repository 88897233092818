import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})

export class SwalService {

    actionEvent = new BehaviorSubject<any>({});

    async input(params?: any): Promise<SweetAlertResult> {
        const base: SweetAlertOptions = {
            title: 'Razon',
            text: 'Favor de proporcionar las razones',
            input: 'textarea',
            inputPlaceholder: 'Razones',
            inputAttributes: {
                autocapitalize: 'on'
            },
            showCancelButton: true,
            confirmButtonColor: '#128f76',
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            preConfirm: data => {
                if (data === '') {
                    Swal.showValidationMessage('El campo no puede estar vacio');
                }
            }
        };

        if (params) {
            Object.assign(base, params);
        }

        return Swal.isVisible() ? null : Swal.fire(base);
    }

    async error(params?: any): Promise<SweetAlertResult> {
        const base: SweetAlertOptions = {
            icon: 'error',
            title: 'Error',
            text: 'Ocurrio un error al procesar los datos',
            confirmButtonColor: '#128f76',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            onClose: () => {
                // call (event)DatatableService to broadcast the params
            }
        };

        if (params) {
            Object.assign(base, params);
        }

        return Swal.isVisible() ? null : Swal.fire(base);
    }

    async success(params?: any): Promise<SweetAlertResult> {
        const base: SweetAlertOptions = {
            icon: 'success',
            title: '!Exito!',
            text: 'Datos procesados exitosamente',
            confirmButtonColor: '#128f76',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            onClose: () => {
                // call (event)DatatableService to broadcast the params
            }
        };

        if (params) {
            Object.assign(base, params);
        }

        return Swal.isVisible() ? null : Swal.fire(base);
    }

    async warning(params?: any): Promise<SweetAlertResult> {
        const base: SweetAlertOptions = {
            icon: 'warning',
            title: 'Atención',
            text: 'Se modificará la siguiente información',
            confirmButtonColor: '#128f76',
            confirmButtonText: 'Si',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
            onClose: () => {
                // call (event)DatatableService to broadcast the params
            }
        };

        if (params) {
            Object.assign(base, params);
        }

        return Swal.isVisible() ? null : Swal.fire(base);
    }

    async info(info: string, params?: any, title = ''): Promise<SweetAlertResult> {
        const base: SweetAlertOptions = {
            icon: 'info',
            title,
            text: info,
            confirmButtonColor: '#128f76',
            confirmButtonText: 'OK',
            showCancelButton: false,
            allowOutsideClick: true,
            onClose: () => {
                // call (event)DatatableService to broadcast the params
            }
        };

        if (params) {
            Object.assign(base, params);
        }

        return Swal.isVisible() ? null : Swal.fire(base);
    }

    async select(params?: any): Promise<SweetAlertResult> {
        const base: SweetAlertOptions = {
            title: 'Seleccione una opción',
            text: 'Favor de seleccionar la opción deseada',
            input: 'select',
            inputPlaceholder: 'Seleccione una opción',
            inputOptions: params.options,
            showCancelButton: true,
            confirmButtonColor: '#128f76',
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar'
        };

        if (params) {
            Object.assign(base, params);
        }

        return Swal.isVisible() ? null : Swal.fire(base);
    }

    async customForm(params?: any): Promise<SweetAlertResult> {
        const base: SweetAlertOptions = {
            title: '',
            showCancelButton: true,
            confirmButtonColor: '#128f76',
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            html: params.inputs,
            preConfirm: () => {
                const formValues = [];
                const elementsById = document.getElementById(params.id).children;
                const elementsByIdArr = Array.prototype.map.call(elementsById, element => element.id);
                elementsByIdArr.forEach(element => {
                    const element_name = element;
                    formValues.push({[element_name]: (document.getElementById(element) as HTMLInputElement).value});
                });

                return formValues;
            }
        };

        if (params) {
            Object.assign(base, params);
        }

        return Swal.isVisible() ? null : Swal.fire(base);
    }
}
