import {
  Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnDestroy, OnInit,
  QueryList, Renderer2, ViewChild, ViewChildren, ViewContainerRef
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { BroadcastService } from '../../services/broadcast.service';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})

export class DatatableComponent implements OnInit, OnDestroy {

  @Input('options') options: any;

  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  @ViewChildren('trRef', { read: ElementRef }) trRef: QueryList<ElementRef>;
  @ViewChildren('trChild', { read: ViewContainerRef }) trChild: QueryList<ViewContainerRef>;

  dtService: Subscription;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  processing = false;
  filters = {};
  items = [];
  ticketAction: number;
  openRows = [];
  superAdmin: boolean;

  tableService$: Subscription;

  constructor(
    private readonly viewRef: ViewContainerRef,
    private readonly _renderer: Renderer2,
    private readonly compFactory: ComponentFactoryResolver,
    private readonly broadcast: BroadcastService
  ) { }

  ngOnInit(): void {
    const dtOpts = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      searchDelay: 800,
      responsive: true,
      language: {
        url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      initComplete(): void {
        // tslint:disable-next-line: no-invalid-this
        $(this.api().table().container()).find('input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      ajax: (dataTablesParameters: any, callback) => {
        const baseService = this.options.config.base;
        const baseFunction = this.options.config.api;

        if (dataTablesParameters) {
          this.processing = true;
          this.tableService$ = baseService[`${baseFunction}`](dataTablesParameters, this.filters)
            .subscribe((resp: any) => {
              this.items = resp.data;
              this.superAdmin = resp.superAdmin;
              this.processing = false;

              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: resp.data
              });
            });
        }
      },
      columns: (() => {
        const cols = [];
        this.options.columns.forEach(col => {
          const columnParam = {
            data: `${col.field}`,
            orderable: true
          };
          if (col.field === '') {
            columnParam.data = null;
            columnParam.orderable = false;
          }

          if (col.field.indexOf('.') > -1) {
            columnParam.data = null;
          }

          if (col.orderable === false) {
            columnParam.orderable = false;
          }

          if (col.width) {
            // tslint:disable-next-line: no-string-literal
            columnParam['width'] = col.width;
          }
          cols.push(columnParam);
        });

        return cols;
      })()
    };

    if ('order' in this.options.config) {
      Object.assign(dtOpts, { order: this.options.config.order });
    }

    if ('ordering' in this.options.config) {
      Object.assign(dtOpts, { ordering: this.options.config.ordering });
    }

    if ('searching' in this.options.config) {
      Object.assign(dtOpts, { searching: this.options.config.searching });
    }

    if ('paging' in this.options.config) {
      Object.assign(dtOpts, { paging: this.options.config.paging });
    }

    if ('params' in this.options.config) {
      Object.assign(this.filters, this.options.config.params);
    }
    this.dtOptions = dtOpts;

    this.dtService = this.broadcast.events
      .subscribe(event => {
        switch (event.name) {
          case 'datatable-filter': this.filterChange(event.data); break;
          // case 'datatable-show-childTable': this.expandRow(event.data); break;
        }
      });
  }

  /**
   * Experimental
   * @param rowData asdasdasd
   */
  expandRow(rowData): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      let index = 0;
      for (let i = 0; i < this.trRef.length; i++) {
        if (this.trRef.toArray()[i].nativeElement === rowData.ref) {
          index = i;
        }
      }

      const row = dtInstance.row(rowData.ref);
      row.push([index]);

      if (row.child.isShown()) {
        row.child.hide();
        this._renderer.removeClass(rowData.ref, 'shown');
        this.openRows.splice(this.openRows.indexOf(index), 1);
      } else {
        this.openRows.push(index);
        let contRef;

        setTimeout(() => {
          contRef = this.trChild.find(child => {
            const elem = child.element;
            const id = parseInt(elem.nativeElement.id.split('child-')[1], 10);

            return id === index;
          });

          if (contRef) {
            row.child('<p>holi</p>').show();
            this._renderer.addClass(rowData.ref, 'shown');

            let childRow: ComponentRef<any>;
            const factory = this.compFactory.resolveComponentFactory(DatatableComponent);
            childRow = contRef.createComponent(factory);
            childRow.instance.options = this.options.childTable;

          }
        });
      }
    });
    // this.broadcast.events.complete();
  }

  rowClick(data): void {
    this.broadcast.fire({
      name: `${this.options.config.type}-row-click`,
      data
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();

    if (this.tableService$) {
      this.tableService$.unsubscribe();
    }

    if (this.dtService) {
      this.dtService.unsubscribe();
    }
  }

  onClickAction(value): void {
    this.ticketAction = value;
  }

  filterChange(params): void {
    if (params.multi) {
      Object.keys(params).forEach(key => {
        if (key !== 'multi') {
          Object.assign(this.filters, { [key]: params[key] });
        }
      });
    } else {
      Object.assign(this.filters, { [params.type]: params.value });
    }

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
    }).catch();
  }

  isVisible(conditionallity?): any {
    // tslint:disable-next-line: no-eval
    return eval(conditionallity);
  }
}
