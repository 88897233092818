import * as i0 from "@angular/core";
var HelpersService = /** @class */ (function () {
    function HelpersService() {
    }
    /**
     * formatDate
     * Formatea una fecha.
     */
    HelpersService.prototype.formatDate = function (date) {
        var formatedDate = new Date(date + "T11:59:59");
        return formatedDate;
    };
    /**
     * flatObject
     * @param obj objeto a reducir a un solo nivel
     * @returns objeto reducido a un solo nivel
     */
    HelpersService.prototype.flatObject = function (obj) {
        var _this = this;
        var flattened = {};
        Object.keys(obj).forEach(function (key) {
            var value = obj[key];
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                Object.assign(flattened, _this.flatObject(value));
            }
            else {
                flattened[key] = value;
            }
        });
        return flattened;
    };
    HelpersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelpersService_Factory() { return new HelpersService(); }, token: HelpersService, providedIn: "root" });
    return HelpersService;
}());
export { HelpersService };
