import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { ChargesService } from '../../../services/charges.service';
import { SwalService } from '../../../services/swal.service';
import { environment } from '../../../../environments/environment';
import { ClientsService } from '../../../services/clients.service';
import { HelpersService } from '../../../services/helpers.service';
import { monthsNumber } from '../../../constants/months';
var UniquePaymentComponent = /** @class */ (function () {
    function UniquePaymentComponent(activeModal, modal, clientsService, chargesService, formBuilder, fromService, helpersService, broadcast, swal) {
        this.activeModal = activeModal;
        this.modal = modal;
        this.clientsService = clientsService;
        this.chargesService = chargesService;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.helpersService = helpersService;
        this.broadcast = broadcast;
        this.swal = swal;
        this.subscriptions = [];
        this.subAccounts = [];
        this.accounts = [];
        this.accountsWithDebts = false;
        this.showMultipleAccounts = false;
        this.uniqueCharge = false;
        this.totalUniqueCharge = 0;
        this.loaded = false;
        this.months_ahead = environment.months_ahead;
        this.payment_types = environment.payment_types;
        this.monthsNumber = monthsNumber;
        this.form = this.formBuilder.group({
            months_ahead: ['', Validators.required],
            payment_types: ['', Validators.required],
            unique_charge: [true],
            charges: [''],
            total: [0]
        });
    }
    UniquePaymentComponent.prototype.ngOnInit = function () {
        this.getAccountsData();
        this.fromService.setForm(this.form);
    };
    UniquePaymentComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
    * manualPayment
    * Envia a la API los parametros para generar un cargo OXXO/SPEI/Tarjeta ya sea para primer pago, pago de deuda, pago de meses adelantados
    */
    UniquePaymentComponent.prototype.manualPayment = function () {
        var _this = this;
        this.paymentType = this.form.get('payment_types').value;
        if (this.form.valid) {
            this.form.get('total').setValue(this.totalManualCharge());
            this.subscriptions.push(this.chargesService.createManualCharge(this.form.value).subscribe(function (resp) {
                if (resp.success) {
                    _this.showSuccessModal(_this.paymentType, resp);
                    _this.activeModal.dismiss();
                }
                else {
                    _this.swal.error({ title: 'Ocurrio un error al momento de generar el pago' });
                }
            }));
        }
    };
    /**
     * totalManualCharge
     * Calcula el total del pago a generar
     * @returns total del pago
     */
    UniquePaymentComponent.prototype.totalManualCharge = function () {
        var total = 0;
        if (this.loaded) {
            var params = {
                idChargeType: 6,
                accounts: this.accounts
            };
            if (!this.accountsWithDebts) {
                Object.assign(params, { months_ahead: this.form.get('months_ahead').value });
            }
            total = this.chargesService.calculateTotalForManual(params);
        }
        return Math.round(total * 100) / 100;
    };
    UniquePaymentComponent.prototype.getAccountsData = function () {
        var _this = this;
        this.subscriptions.push(this.clientsService.show(this.data.id_clients).subscribe(function (resp) {
            _this.data = resp.response;
            _this.setAccountsData();
        }));
    };
    /**
     * setAccountsData
     * Inicializa los valores de las cuentas a cobrar
     */
    UniquePaymentComponent.prototype.setAccountsData = function () {
        var _this = this;
        var _a;
        this.parentAccount = this.formatChargesData(this.data);
        this.accounts.push(this.parentAccount);
        if (this.data.sub_accounts.length > 0) {
            this.subAccounts = this.data.sub_accounts.map(function (sub) {
                var subAccount = _this.formatChargesData(sub);
                if (subAccount) {
                    return subAccount;
                }
            });
            (_a = this.accounts).push.apply(_a, this.subAccounts);
            this.form.controls.charges.setValue(this.accounts);
        }
        this.loaded = true;
    };
    /**
     * formatChargeData
     * Evalua a las cuentas asociadas a una misma familia para generar el objeto base
     * de los cargos que incluira cada una de las cuentas validas
     * @param data información del cliente
     * @returns formated data {idChargeType: number, id_clients: number, fixed_monthly_fee: number, name: string, text: string, debts?: array}
     */
    UniquePaymentComponent.prototype.formatChargesData = function (data) {
        var formatedData = {};
        if (data.status === 'accepted' && data.completed_installation) {
            var pendingDebts = data.pending_debts.length;
            var text = 'Mensualidad';
            var idChargeType = 2;
            var fixedMonthlyFee = data.fixed_monthly_fee;
            var date = data.next_payday;
            if (pendingDebts > 0) {
                var evaluateDebts = this.chargesService.evaluateDebts(data.pending_debts);
                fixedMonthlyFee = evaluateDebts.totalDebt;
                idChargeType = evaluateDebts.idChargeType;
                text = 'Pago tardio';
                date = data.pending_debts[0].monthly_charge_date;
                text = pendingDebts === 1 ? text : text + " acumulado";
                if (!this.accountsWithDebts) {
                    this.accountsWithDebts = true;
                    if (this.form.get('months_ahead')) {
                        this.form.removeControl('months_ahead');
                    }
                }
            }
            formatedData = {
                id_clients: data.id_clients,
                fixed_monthly_fee: fixedMonthlyFee,
                name: data.name,
                id_charge_type: idChargeType,
                text: text
            };
            if (pendingDebts < 2) {
                var formatedDate = this.helpersService.formatDate(date);
                formatedData['text'] = text + " (" + formatedDate.getFullYear() + " - " + this.monthsNumber[formatedDate.getMonth()] + ")";
            }
            else {
                Object.assign(formatedData, { acumulatedLatePayment: true, debts: data.pending_debts });
            }
        }
        return formatedData;
    };
    /**
    * showSuccessModal
    * Muestra el modal de exito cuando una referencia OXXO|SPEI o cargo por tarjeta es efectuado exitosamente.
    * @param paymentType tipo de cargo que se realizo Tarjeta|OXXO|SPEI
    * @param resp unicamente se usa si el cargo fue un OXXO|SPEI para mostra el modal de la información de la referencia.
    */
    UniquePaymentComponent.prototype.showSuccessModal = function (paymentType, resp) {
        var _this = this;
        if (paymentType === 'CARD') {
            this.swal.success({ title: 'Transación efectuada exitosamente' }).then(function () {
                _this.activeModal.dismiss();
                _this.broadcast.reloadDataTable();
            });
        }
        else {
            this.offline_format = this.chargesService.formatMessage(resp, paymentType);
            this.swal.success({ title: this.offline_format.title, html: this.offline_format.msg }).then(function () {
                _this.activeModal.dismiss();
                _this.broadcast.reloadDataTable();
            });
        }
    };
    return UniquePaymentComponent;
}());
export { UniquePaymentComponent };
