<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <div class="row">
        <div class="col">
            <h2 class="m-0">Mapa del seguimiento</h2>
        </div>
        <div class="col text-right">
            <button class="{{button.style}}" (click)="buttonStatus()"><i class="{{button.icon}}"></i></button>
            <button *ngIf="!simulateTickets" type="button" class="btn btn-primary"
                (click)="getTicketsNextMonth()">Simular tickets</button>
        </div>
    </div>
</div>
<div class="container-fluid border-bottom bg-white p-3 mb-4" *ngIf="show_filters">
    <div class="row" *ngIf="executed">
        <div class="col" *ngIf="tickets_status">
            <app-form-field type="multiselect" [options]="{
                multiple: true,
                values: tickets_status,
                label: '',
                placeholder: 'Seleccione el estado',
                path: 'status'
            }">
            </app-form-field>
        </div>
        <div class="col" *ngIf="tickets_types">
            <app-form-field type="multiselect" [options]="{
                multiple: true,
                values: tickets_types,
                label: '',
                placeholder: 'Seleccione el tipo de ticket',
                path: 'type'
            }">
            </app-form-field>
        </div>
        <div class="col" *ngIf="technicians">
            <app-form-field type="multiselect" [options]="{
                multiple: true,
                values: technicians,
                label: '',
                placeholder: 'Selecciona al tecnico',
                path: 'technician'
            }">
            </app-form-field>
        </div>
        <div class="col" *ngIf="clients">
            <app-form-field type="multiselect" [options]="{
                multiple: true,
                values: clients,
                label: '',
                placeholder: 'Selecciona al cliente',
                searchable: true,
                virtualScroll: true,
                path: 'id_clients'
            }">
            </app-form-field>
        </div>
    </div>
    <br>
    <div class="text-right">
        <button type="button" class="btn btn-outline-success" [disabled]="!form.dirty" (click)="filterMap()">Filtrar
            mapa</button>
    </div>
</div>
<div class="container-fluid bg-white p-3">
    <table class="table">
        <tbody>
            <tr>
                <td>Abierto</td>
                <td><img src="assets\img\tickets_status\opened-marker-filter_change.png"
                        alt="opened-marker-filter_change" /></td>
                <td><img src="assets\img\tickets_status\opened-marker-installation.png"
                        alt="opened-marker-installation.png" /></td>
                <td><img src="assets\img\tickets_status\opened-marker-service_call.png"
                        alt="opened-marker-service_call" /></td>
                <td><img src="assets\img\tickets_status\simulated-marker.png" alt="simulated-marker" /></td>
            </tr>
            <tr>
                <td>Asignado</td>
                <td><img src="assets\img\tickets_status\assigned-marker-filter_change.png"
                        alt="assigned-marker-filter_change" /></td>
                <td><img src="assets\img\tickets_status\assigned-marker-installation.png"
                        alt="assigned-marker-installation" /></td>
                <td><img src="assets\img\tickets_status\assigned-marker-service_call.png"
                        alt="assigned-marker-service_call" /></td>
            </tr>
            <tr>
                <td></td>
                <td>Cambio de filtros</td>
                <td>Instalación</td>
                <td>Soporte</td>
                <td>Simulado</td>
            </tr>
        </tbody>
    </table>

    <agm-map [latitude]="lat" [longitude]="lng" [zoomControl]="true">
        <span *ngIf="tickets.length > 0">
            <ng-container *ngFor="let ticket of tickets">
                <agm-marker *ngIf="ticket.client.coordinate !== null" [title]="ticket.client.name"
                    [iconUrl]="ticketStatusUrl(ticket)" 
                    [latitude]="ticket.client.coordinate.latitude"
                    [longitude]="ticket.client.coordinate.longitude" 
                    (markerClick)="clickedMarker(infowindow)">
                    <agm-info-window [maxWidth]="400" #infowindow>
                        <dl class="row">
                            <dt class="col-sm-4">Nombre: </dt>
                            <dd class="col-sm-8">{{ticket.client.name}}</dd>
                            <dt class="col-sm-4">Teléfono: </dt>
                            <dd class="col-sm-8">{{ticket.client.phone}}</dd>
                            <dt class="col-sm-4">Correo: </dt>
                            <dd class="col-sm-8">{{ticket.client.email}}</dd>
                            <dt *ngIf="!simulateTickets" class="col-sm-4">Ticket #</dt>
                            <dd *ngIf="!simulateTickets" class="col-sm-8">{{ticket.id_tickets}} ({{ticket.type}})</dd>
                            <dt class="col-sm-4">Descripción: </dt>
                            <dd class="col-sm-8">{{ticket.description}}</dd>
                            <dt *ngIf="ticket.client.installation_extras && ticket.client.installation_extras.length > 0" class="col-sm-4">
                                Extras: 
                            </dt>
                            <dd *ngIf="ticket.client.installation_extras && ticket.client.installation_extras.length > 0" class="col-sm-8">
                                <ul *ngFor="let extra of ticket.client.installation_extras">
                                    <li>{{extra.element_name}}</li>
                                </ul>
                            </dd>
                            <dt *ngIf="!simulateTickets" class="col-sm-4">Creado el: </dt>
                            <dd *ngIf="!simulateTickets" class="col-sm-8">{{ticket.created_at}}</dd>
                        </dl>
                        <span *ngIf="!simulateTickets">
                            <dl class="row"
                                *ngIf="ticket.assignations && ticket.assignations.length && ticket.assignations[0].technician">
                                <dt class="col-sm-4">Asignado a: </dt>
                                <dd class="col-sm-8">{{ getAssignedTechnician(ticket.assignations).name }} ({{
                                    getAssignedTechnician(ticket.assignations).email }})</dd>
                                <dt class="col-sm-4">Asignado el: </dt>
                                <dd class="col-sm-8">{{ getAssignedTechnician(ticket.assignations).assignationDate }}</dd>
                            </dl>
                            <div [ngSwitch]="(ticket.status)">
                                <div class="text-center" *ngSwitchCase="('opened')">
                                    <button class="btn btn-success" (click)="assign(ticket)">Asignar</button>
                                    <button class="btn btn-secondary" (click)="unrealized(ticket)">No realizado</button>
                                </div>
                                <div class="text-center" *ngSwitchCase="('assigned')">
                                    <button class="btn btn-danger" (click)="unassign(ticket)">Desasignar</button>
                                    <button class="btn btn-primary" (click)="reassign(ticket)">Reasignar</button>
                                    <button class="btn btn-secondary" (click)="unrealized(ticket)">No realizado</button>
                                </div>
                            </div>
                        </span>
                    </agm-info-window>
                </agm-marker>
                <agm-marker [title]="warehouse.name" [iconUrl]="warehouse.icon" [latitude]="warehouse.lat"
                    [longitude]="warehouse.lng">
                </agm-marker>
            </ng-container>
        </span>
    </agm-map>
</div>