import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SwalService } from './swal.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./swal.service";
var ClientsService = /** @class */ (function () {
    function ClientsService(http, swal) {
        this.http = http;
        this.swal = swal;
        this.baseUrl = "" + environment.apiUrl;
    }
    ClientsService.prototype.getClients = function (params, filters) {
        if (filters && filters.id_users !== 0) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/clients/get_clients", params);
    };
    ClientsService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/clients/" + id);
    };
    ClientsService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/clients/" + id);
    };
    ClientsService.prototype.setStatus = function (id, params) {
        return this.http.post(this.baseUrl + "/clients/" + id + "/setStatus", params);
    };
    ClientsService.prototype.checkActiveOfflineReference = function (id) {
        return this.http.get(this.baseUrl + "/client/" + id + "/checkActiveOfflineReference");
    };
    ClientsService.prototype.makeSalesAgent = function (id, params) {
        return this.http.post(this.baseUrl + "/clients/" + id + "/attachRole", params);
    };
    ClientsService.prototype.update = function (id, params) {
        return this.http.put(this.baseUrl + "/clients/" + id, params);
    };
    ClientsService.prototype.getHierarchicalTree = function (id) {
        return this.http.get(this.baseUrl + "/clients/" + id + "/hierarchicalTree");
    };
    ClientsService.prototype.getImages = function (id) {
        return this.http.get(this.baseUrl + "/clients/" + id + "/getImages");
    };
    ClientsService.prototype.getHistory = function (id) {
        var id_clients = id.id_clients;
        var id_users = id.id_users;
        var paramsClient = {
            params: { id_clients: id_clients }
        };
        var paramsUser = {
            params: { id_users: id_users }
        };
        var corrections = this.http.get(this.baseUrl + "/corrections", paramsClient);
        var invalidations = this.http.get(this.baseUrl + "/invalidations", paramsClient);
        var rejections = this.http.get(this.baseUrl + "/rejections", paramsClient);
        var cancellations = this.http.get(this.baseUrl + "/cancellations", paramsClient);
        var notifications = this.http.get(this.baseUrl + "/notifications", paramsUser);
        return forkJoin([corrections, invalidations, rejections, cancellations, notifications]);
    };
    ClientsService.prototype.getLogs = function (params) {
        var logParams = {
            params: {
                id_users: params.id_users,
                id_clients: params.id_clients
            }
        };
        return this.http.get(this.baseUrl + "/logs", logParams);
    };
    ClientsService.prototype.getPriceHistory = function (params) {
        return this.http.get(this.baseUrl + "/prices/client-price-change-logs/" + params.id_clients);
    };
    ClientsService.prototype.updateMonthlyFee = function (id_client, params) {
        return this.http.put(this.baseUrl + "/clients/" + id_client + "/updateMonthlyFee", params);
    };
    ClientsService.prototype.updatePlan = function (id_client, params) {
        return this.http.put(this.baseUrl + "/clients/" + id_client + "/updateClientPlan", params);
    };
    ClientsService.prototype.calculateMonthlyFee = function (client) {
        var monthly_fee = Number(client.monthly_fee);
        if (client.social_responsability) {
            var sr = ((monthly_fee / 1.16) * 0.007);
            monthly_fee = (monthly_fee + sr);
        }
        monthly_fee = parseFloat(monthly_fee.toFixed(0)) / 100;
        return monthly_fee;
    };
    ClientsService.prototype.getClientsName = function () {
        return this.http.get(this.baseUrl + "/clients?status=accepted").pipe(map(function (result) {
            if (result.success) {
                return result.response.map(function (client) {
                    return ({ id: client.id_clients, name: client.name });
                });
            }
        }));
    };
    /**
     * checkLastPayaments
     *
     * @param id_client id del cliente al cual se le van a verificar sus ultimos cargos OXXO|SPEI|TARJETA
     */
    ClientsService.prototype.checkLastPayments = function (id_client) {
        var last_reference = this.http.get(this.baseUrl + "/client/" + id_client + "/checkActiveOfflineReference");
        var last_card_charge = this.http.get(this.baseUrl + "/client/" + id_client + "/checkLastCardCharge");
        return forkJoin([last_reference, last_card_charge]);
    };
    /**
     * getClientByEmail
     * Manda una petición GET para obtener mediante el correo la información de un cliente registrado
     * en la BD.
     * @param email del referido
     * @returns 200 | 404 objeto con los datos del referido
     */
    ClientsService.prototype.getClientByEmail = function (email) {
        return this.http.get(this.baseUrl + "/clients?email=" + email);
    };
    ClientsService.prototype.registerClient = function (params) {
        return this.http.post(this.baseUrl + "/contracts", params);
    };
    /**
     * changeDeviceRecoveryStatus
     *
     * @param id_client int id del cliente
     * @param params booleano que determina el status para el dispositivo
     * @returns 200 | 404
     */
    ClientsService.prototype.changeDeviceRecoveryStatus = function (id_client, params) {
        return this.http.post(this.baseUrl + "/clients/" + id_client + "/changeDeviceRecoveryStatus", params);
    };
    /**
     * updateClientsSalesGroup
     * Envia una petición POST para actualizar el grupo de ventas de los clientes
     * @param params optional listado de id de clientes aceptados
     * @returns 200 | 404
     */
    ClientsService.prototype.updateClientsSalesGroup = function (params) {
        return this.http.post(this.baseUrl + "/clients/updateClientsSalesGroup", params);
    };
    /**
     * associateChildAccounts
     * Envia una petición PUT para asociar un array de id a una cuenta padre.
     * @param id del cliente que sera el padre
     * @param params {childAccounts: Array<number>}
     * @returns 200 | 400 | 404
     */
    ClientsService.prototype.associateChildAccounts = function (id, params) {
        return this.http.put(this.baseUrl + "/clients/" + id + "/associateChildAccounts", params);
    };
    /**
     * disAssociateChildAccounts
     * Envia una petición PUT para volver una cuenta hija una cuenta regular.
     * @param id del cliente hijo que se volvera cuenta individual
     * @returns 200 | 400 | 404
     */
    ClientsService.prototype.disAssociateChildAccounts = function (id, params) {
        return this.http.put(this.baseUrl + "/clients/" + id + "/disAssociateChildAccounts", params);
    };
    ClientsService.prototype.assignPromotionalMonthlyFee = function (params) {
        return this.http.post(this.baseUrl + "/clients/assignPromotionalMonthlyFee", params);
    };
    ClientsService.prototype.getClientPromotionalMonthlyFee = function (params, filters) {
        var id = 0;
        if (filters) {
            id = filters.id_clients;
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/clients/" + id + "/getClientPromotionalMonthlyFeesTable", params);
    };
    ClientsService.prototype.addClientExtraImages = function (params, id_clients) {
        return this.http.post(this.baseUrl + "/clients/" + id_clients + "/addExtraImages", params);
    };
    ClientsService.prototype.getImagesExtraCategories = function () {
        return this.http.get(this.baseUrl + "/clients/getClientExtraImagesCategories");
    };
    ClientsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientsService_Factory() { return new ClientsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SwalService)); }, token: ClientsService, providedIn: "root" });
    return ClientsService;
}());
export { ClientsService };
