<div>
    <h6>
        {{data.name}}
        <br>
        <span *ngIf="data.is_parent" class="fa fa-users"></span>
        <i *ngIf="checkServiceType(data.id_plan_type)" class="fa fa-star text-warning"></i>
        <img *ngIf="data.preferred_payment_method" src="{{loadPreferredPaymentMethodImg(data.preferred_payment_method)}}" alt="N/A" />
    </h6>
    <div *ngIf="data.parent_name">
        <i class="fa fa-child"></i>
        Cuenta hija de: <br>
        {{data.parent_name}}
    </div>
    <div *ngIf="data.invoice_data || data.rfc; else notCaptured">
        <a class="text-info">Datos de facturación capturados</a>
    </div>
    <ng-template #notCaptured>
        <div>
            <a class="text-warning">Datos de facturación no capturados</a>
        </div>
    </ng-template>
    <span class="badge badge-{{statusBadge}}">{{statusInstallText}}</span>
    <br>
    <div *ngIf="data.serial_number">
        <a class="font-weight-bold">Número de serie</a>
        <br>
        <i class="fa fa-tint fa-lg text-info fa-fw"></i>
        <a>{{data.serial_number}}</a>
    </div>
    <div *ngIf="data.anual_payment">
        <span class="badge badge-info">Pago anual</span>
    </div>
    <div *ngIf="data.extra_filter_change">
        <span class="badge badge-info">Tickets extra</span>
    </div>
    <div *ngIf="data.unique_charge">
        <span class="badge badge-info">Pago familiar automatico</span>
    </div>
    <div *ngIf="data.lost_device">
        <span class="badge badge-danger">Equipo no recuperado</span>
    </div>
    <div *ngIf="data.leadsource"><a>Leadsource: </a><a>{{data.leadsource.name}}</a></div>
    <a>ID del cliente: </a> <a>{{data.id_clients}}</a>
</div>