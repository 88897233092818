import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { SalesDashboardService } from '../../../../services/sales-dashboard.service';
import { SwalService } from '../../../../services/swal.service';

@Component({
  selector: 'app-sales-campaign-chart',
  templateUrl: './sales-campaign-chart.component.html',
  styleUrls: ['./sales-campaign-chart.component.scss']
})
export class SalesCampaignChartComponent implements OnInit {

  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

  @Output()
  isLoadCampaignChart = new EventEmitter<boolean>();

  isLoad = true;

  barChartColors: Array<Color> = [
    { backgroundColor: '#ff843c' },
    { backgroundColor: '#83b941' },
    { backgroundColor: '#16a4db' }
  ];

  barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true
          }
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true
          }
        }]
    },
    plugins: {
      plugins: {
        datalabels: {
          color: 'black',
          labels: {
            title: {
              font: {
                weight: 'bold'
              }
            },
            value: {
              color: 'black'
            }
          }
        }
      }
    }
  };
  barChartLabels: Array<Label> = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [pluginDataLabels];
  labelsValue: Array<any> = [];

  barChartData: Array<ChartDataSets> = [];

  form: FormGroup = this.formBuilder.group({
    from: [],
    to: []
  });

  fieldFrom: FormControl;
  fieldTo: FormControl;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly swal: SwalService,
    private readonly salesDashboard: SalesDashboardService
  ) { }

  ngOnInit(): void {
    // setting timeout to avoid call same request to hubspot at same time.    
    this.setChart();
    this.fieldFrom = this.form.get('from') as FormControl;
    this.fieldTo = this.form.get('to') as FormControl;
  }

  search(): void {
    const fromSub = this.form.get('from').value;
    const toSub = this.form.get('to').value;
    const params = {
      from: fromSub,
      to: toSub
    };
    if (params.from && params.to) {
      this.setChart(params);
    } else {
      this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
    }
  }

  setChart(dateRangeParams?): void {
    this.isLoad = true;
    this.salesDashboard.salesByCampaign(dateRangeParams).subscribe((res: any) => {
      // getting labels                        
      if (res.success) {
        const params = {
          name: 'etiqueta_de_origen'
        };
        this.salesDashboard.contactProperty(params).subscribe(response => {
          if (response.success) {
            const barChartLabelData = [];
            const keys = Object.keys(res.response);
            for (const index of keys) {
              if (index) {
                for (const option of response.response.data.options) {
                  if (option.value === index) {
                    barChartLabelData.push(option.label);
                  }
                }
              }
            }
            this.barChartLabels = [...barChartLabelData];
          }
        });

        const nameParam = {
          name: 'lead_promocion'
        };
        this.salesDashboard.contactProperty(nameParam).subscribe(response => {
          if (response.success) {
            const keys = Object.keys(res.response);
            const barChartDataL = [];
            const closeRateData = [];
            for (const option of response.response.data.options) {
              const countByData = [];
              for (const index of keys) {
                const arrayByLabel = res.response[index].filter(contact =>
                  contact.lead_promocion.indexOf(option.value) > -1);
                countByData.push(arrayByLabel.length);
                if (option.value === 'lead_ganado') {
                  closeRateData[index] = { ...closeRateData[index], lead_ganado: arrayByLabel.length };
                  // this.closeRateData[index].push({ lead_ganado: arrayByLabel.length });                  
                }
                if (option.value === 'lead_perdido') {
                  closeRateData[index] = { ...closeRateData[index], lead_perdido: arrayByLabel.length };
                }
              }
              const data = { data: countByData, label: option.label, yAxisID: 'y-axis-1' };
              barChartDataL.push(data);
            }

            const closeRateChart = [];
            for (const index of keys) {
              if (closeRateData[index].lead_ganado > 0 && closeRateData[index].lead_perdido > 0) {
                const closeRate = (closeRateData[index].lead_ganado /
                  // tslint:disable-next-line: restrict-plus-operands
                  (closeRateData[index].lead_ganado + closeRateData[index].lead_perdido)) * 100;
                const valueFinal = +closeRate.toFixed(2);
                closeRateChart.push(valueFinal);
              } else {
                closeRateChart.push(0);
              }
            }
            // cuantos lead ganados por facebok ad lead, etc
            const closeRateDataChart = {
              data: closeRateChart, label: 'Close Rate', type: 'line', datalabels: {
                formatter: (value, context) =>
                  `${value}%`
              },
              yAxisID: 'y-axis-2'
            };
            barChartDataL.push(closeRateDataChart);
            // group by options            
            this.barChartData = [...barChartDataL];
            // this.barChartData = barChartDataL;
            this.isLoad = false;
            this.isLoadCampaignChart.emit(true);
          }
        });
      } else {
        this.swal.error({ title: 'Error recuperando los datos para la gráfica ventas por mes, intente de nuevo' });
      }
    });
  }

  showInfoGraphic(): void {
    const message = `How many closed won, lost and valid leads based on origin of lead filtered by date range.
     Close Rate: Closed Won/Closed Lost`;
    this.swal.info(message);
  }
}
