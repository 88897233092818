<div class="modal-body">
    <div class="row d-block">
        <div class="col-sm-6 serial-number" *ngIf="data.type !== 'installation' || data.type !== 'removal'">
            <div class="row">
                <div class="col-sm-10">
                    <h2>Actualizar número de serie</h2>
                </div>
                <div class="col-sm-2">
                    <app-form-field type="checkbox" (checkboxValue)="updateSerialNumber($event)"
                        [options]="{value: show_serial_number}">
                    </app-form-field>
                </div>
            </div>
            <div  *ngIf="show_serial_number">
                <app-form-field type="input" [options]="{path: 'serial_number', width: 12}">
                </app-form-field>
            </div>
        </div>
        <div *ngIf="credit_remaining_amount > 0" class="col-sm-6">
            <h2>Credito disponible: {{credit_remaining_amount | currency}}</h2>
        </div>

        <div class="col-sm-6">
            <h2>Cuota final de servicio</h2>
            <div class="input-group">
                <span class="input-group-addon">
                    <i class="fa fa-dollar"></i>
                </span>
                <app-form-field type="input" [options]="{label: '' ,path: 'final_service_fee',width: true}">
                </app-form-field>
            </div>
        </div>

        <div class="col-sm-6" *ngIf="payment">
            <h2>Metodo de pago</h2>
            <div class="input-group">
                <span class="input-group-addon">
                    <i class="fa fa-dollar"></i>
                </span>
                <app-form-field type="select" [options]="{
                    label: '' ,
                    path: 'payment_types', 
                    droptext: 'Seleccione una opción...', 
                    elements: payment_types, width: 12
                }">
                </app-form-field>
            </div>
        </div>

        <div class="hr-line-dashed"></div>
        <div class="col-sm-6">
            <div class="form-group" show-errors="{showSuccess: true}">
                <h2>Razones de la cuota final</h2>
                <app-form-field type="textarea" style="border-radius: 0px;" styleInput="textarea"
                    [options]="{rows: '4', path:'final_service_fee_reasons', width: true}"></app-form-field>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('close button')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" (click)="closeTicket()" [disabled]="form.invalid">Cerrar
            Ticket</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>