import { Component, OnDestroy, OnInit } from '@angular/core';
import { TicketsService } from '../../../services/tickets.service';
import { SharedComponent } from '../../../model/shared-component';
import { AssignComponent } from '../tickets/assign/assign.component';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SwalService } from '../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from '../../../services/broadcast.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FromService } from '../../../providers/form.service';
import { UsersService } from '../../../services/users.service';
import { ClientsService } from '../../../services/clients.service';
import { UnrealizedComponent } from '../tickets/unrealized/unrealized.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tickets-map',
  templateUrl: './tickets-map.component.html',
  styleUrls: ['./tickets-map.component.scss']
})
export class TicketsMapComponent implements OnInit, OnDestroy {
  tickets: Array<any> = [];
  subscriptions: Array<Subscription> = [];
  show_filters = false;
  executed = false;
  simulateTickets = false;
  button = {
    icon: 'fa fa-filter',
    style: 'btn btn-success'
  };
  clients = [];
  technicians = [];
  tickets_types = [];
  tickets_status = [
    { id: 'assigned', name: 'Asignado' },
    { id: 'opened', name: 'Abierto' }
  ];
  form: FormGroup = this.formBuilder.group({
    id_clients: [],
    status: [],
    type: [],
    technician: []
  });
  lat = 20.673135;
  lng = -103.350693;
  zoom = 4;
  previous;

  warehouse = {
    name: 'Bodega',
    icon: 'assets/img/tickets_status/warehouse-marker.png',
    lat: 20.600148598973142,
    lng: -103.43025483863697
  };

  constructor(
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly clientsService: ClientsService,
    private readonly ticketService: TicketsService,
    private readonly usersService: UsersService,
    private readonly appModal: ModalComponent,
    private readonly swal: SwalService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'reload-map':
          if (event.data !== 'filtered') { this.getMapTickets(); }
          break;
      }
    }));
    this.getDefaultView();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * clickedMarker
   * Abre un modal con la informacion del marcador seleccionado en el mapa
   * @param infowindow parametros del marcador seleccionado que se encuentran asociados a un ticket
   */
  clickedMarker(infowindow): void {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  /**
   * ticketStatusUrl
   * Obtiene la URL de la imagen a mostrar en el marcador dependiendo del status del ticket
   * @param status del ticket
   * @param type indica el tipo de ticket
   * @returns string que es la url de la imagen que se le va asociar al marcador.
   */
  ticketStatusUrl(ticket): string {
    const premiumPlans = [2, 4];
    const serviceCalls = ['removal', 'move'];
    const type = serviceCalls.includes(ticket.type) ? 'service_call' : ticket.type;
    const status = ticket.status;
    const folder = 'assets/img/tickets_status';
    const marker = type === 'simulated' ? `${type}-marker` : `${status}-marker-${type}`;
    let url = `${folder}/${marker}`;

    if (premiumPlans.includes(ticket.client.id_plan_type)) {
      url += '-p';
    }

    url += '.png';

    return url;
  }

  /**
   * assign
   * Abre el modal para asignar un ticket abierto a un tecnico.
   * @param data Objeto con la información del ticket
   */
  assign(data): void {
    Object.assign(data, { isMap: true });
    const props: SharedComponent = new SharedComponent(
      AssignComponent,
      { ticket: data, status: 'assign' },
      { title: 'Asignación de ticket' }
    );
    this.appModal.openXl(props);
  }

  /**
   * reassign
   * Abre el modal para reasignar un ticket asignado a un tecnico.
   * @param data Objeto con la información del ticket
   */
  reassign(data): void {
    Object.assign(data, { isMap: true });
    const props: SharedComponent = new SharedComponent(AssignComponent, { ticket: data, status: 'reassing' }, { title: 'Reasignación de ticket' });
    this.appModal.openXl(props);
  }

  /**
   * assign
   * Abre el modal para desasignar un ticket asignado a un tecnico.
   * @param data Objeto con la información del ticket
   */
  unassign(data): void {
    const swalParams = {
      title: '¿Desea desasignar este ticket?',
      text: '¡Si desasigna este ticket el estatus pasara a abierto!'
    };

    this.swal.warning(swalParams).then(result => {
      if (result.value) {
        const params = {
          status: 'unassigned'
        };
        this.subscriptions.push(this.ticketService.setStatus(params, data.id_tickets).subscribe((respData: any) => {
          if (respData.success) {
            this.swal.success().then(() => { this.getMapTickets(); });
          } else {
            this.swal.error({ title: 'Ocurrió un error al actualizar los datos' });
          }
        }));
      }
    });
  }

  /**
  * unrealized
  * Abre el modal para marcar un ticket como no realizado.
  * @param data Objeto con la información del ticket
  */
  unrealized(data): void {
    Object.assign(data, { isMap: true });

    const props: SharedComponent = new SharedComponent(UnrealizedComponent, data, { title: `Marcar como no realizado el ticket ${data.id_tickets} del cliente ${data.client.name}` });
    this.appModal.opensm(props);
  }

  /**
   * ButtonStatus
   * Define los parametros a mostrar en el boton del filtrado dependiendo si se muestran o no
   * los elementos de los filtros
   */
  buttonStatus(): void {
    this.show_filters = !this.show_filters ? true : false;
    this.button.icon = this.show_filters ? 'fa fa-window-close' : 'fa fa-filter';
    this.button.style = this.show_filters ? 'btn btn-danger' : 'btn btn-success';
    if (!this.executed) {
      this.loadData();
    }
  }

  /**
   * filterMap
   * Filtra el mapa de tickets dependiendo de los parametros que se le pasaron.
   */
  filterMap(): void {
    const filterValues = this.fromService.getDirtyValues(this.form);
    this.subscriptions.push(this.ticketService.getMapTickets(filterValues).subscribe((resp: any) => {
      if (resp.data.tickets.length > 0) {
        this.broadcast.fire({
          name: 'reload-map',
          data: 'filtered'
        });
        this.tickets = resp.data.tickets;
      } else {
        this.swal.warning({ text: 'No se encontraron datos' });
      }
    }));
  }

  /**
   * getAssignedTechnicians
   * Obtiene la ultima asignación y tecnico asociado a un ticket
   * @param assignations Array con todas las asignaciones que ha tenido el ticket
   * @returns Objeto con la informacion de la assignacion y el tecnico
   */
  getAssignedTechnician(assignations: Array<any>): Object {
    let assignedTechnician: any;
    if (assignations && assignations.length) {
      const assigned = [...assignations];
      assigned.sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1);
      assignedTechnician = {
        name: assigned[0].technician.name,
        email: assigned[0].technician.email,
        assignationDate: assigned[0].updated_at
      };
    }

    return assignedTechnician;
  }


  /**
   * getTicketsNextMonth
   * Obtiene la simulación de tickets del proximo mes para mostrarlos en el mapa
   */
  getTicketsNextMonth(): void {
    this.subscriptions.push(this.ticketService.simTicketsNextMonth().subscribe((resp: any) => {
      this.tickets.push(...resp.data.tickets);
      this.simulateTickets = true;
    }));
  }

  /**
   * getMapTickets
   * Obtiene la lista de tickets abiertos o asignados.
   */
  private getMapTickets(): void {
    this.ticketService.getMapTickets().subscribe((resp: any) => {
      this.tickets = resp.data.tickets;
      this.simulateTickets = false;
    });
  }

  /**
   * getDefaultView
   * Obtiene las coordenadas en donde se centrara el mapa de tickets inicialmente
   * esto dependiendo del team_id del usuario logueado
   */
  private getDefaultView(): void {
    this.subscriptions.push(this.ticketService.getMapTickets().subscribe((resp: any) => {
      this.tickets = resp.data.tickets;
      // switch (resp.data.team) {
      //   case 1:
      //     this.lat = 20.673135;
      //     this.lng = -103.350693;
      //     this.zoom = 9;
      //     break;
      //   case 2:
      //     this.lat = 20.967064;
      //     this.lng = -89.623728;
      //     this.zoom = 12;
      //     break;
      //   default:
      //     this.lat = 20.673135;
      //     this.lng = -103.350693;
      //     this.zoom = 6;
      //     break;
      // }
    }));
  }

  /**
   * loadData
   * Carga los valores de los filtros cuando el boton de filtrado es usado
   * este proceso solo se hace una vez.
   */
  private loadData(): void {
    this.getTicketsTypes();
    this.getActiveTechnicians();
    this.getActiveClients();
    this.fromService.setForm(this.form);
    this.executed = true;
  }

  /**
   * getTicketsTypes
   * Obtiene los tipos de tickets que admite el sistema
   */
  private getTicketsTypes(): void {
    this.subscriptions.push(this.ticketService.getTicketTypes().subscribe((resp: any) => {
      if (resp.success) {
        this.tickets_types = resp.response;
      } else {
        this.swal.error({ text: 'No se pudieron obtener los tipos de tickets' });
      }
    }));
  }

  /**
   * getActiveTechnicias
   * Obtiene el ARRAY con los tecnicos activos en el sistema
   */
  private getActiveTechnicians(): void {
    this.subscriptions.push(this.usersService.getUserByRole({ status: 1 }, { role: { role: 'technician' } }).subscribe((resp: any) => {
      this.technicians = resp.data;
    }));
  }

  /**
   * getActiveClients
   * Obtiene la lista de todos los clientes activos en el sistema
   * TODO: mejorar la obtencion de esta información ya que de momento se traen a todos los clientes
   * lo ideal seria traerlos de la API conforme se va ingresando el nombre.
   */
  private getActiveClients(): void {
    const params = {
      selected_columns: ['id_clients', 'name'],
      status: 'accepted',
      length: '3000'
    };

    this.subscriptions.push(this.clientsService.getClients(params).subscribe((resp: any) => {
      if (resp.data.length > 0) {
        this.clients = resp.data.map(client => ({ id: client.id_clients, name: client.name }));
      }
    }));
  }

}
