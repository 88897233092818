import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-data',
  templateUrl: './client-data.component.html',
  styleUrls: ['./client-data.component.scss']
})
export class ClientDataComponent implements OnInit {
  @Input() data: any;
  preferredPaymentMethod: string;
  statusInstallText: string;
  statusBadge: string;
  ngOnInit(): void {
    this.checkInstallationStatus(this.data.completed_installation);
  }

  /**
   * checkServiceType
   * Verifica el id_plan_type para determinar si se mostrara el icono de premium service
   * id_plan_type: premium service
   * @returns true si el id es 2 caso contrario retorna falso 
   */
  checkServiceType(id_plan_type): boolean {
    if (id_plan_type === 2 || id_plan_type === 4) {
      return true;
    }

    return false;
  }

  loadPreferredPaymentMethodImg(paymentMethod): string {
    switch (paymentMethod) {
      case 1:
        this.preferredPaymentMethod = 'assets/img/preferred_payment_method/card.ico';
        break;
      case 2:
        this.preferredPaymentMethod = 'assets/img/preferred_payment_method/oxxo.ico';
        break;
      case 3:
        this.preferredPaymentMethod = 'assets/img/preferred_payment_method/spei.ico';
        break;
    }

    return this.preferredPaymentMethod;
  }

  checkInstallationStatus(installationTicket): void {
    this.statusInstallText = 'No instalado';
    this.statusBadge = 'secondary';
    if (installationTicket && this.data.status === 'accepted') {
      this.statusInstallText = 'Instalado';
      this.statusBadge = 'success';
    }
  }
}
