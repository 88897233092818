import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    /**
     * formatDate
     * Formatea una fecha.
     */
    formatDate(date: string): Date {
        let formatedDate = new Date(`${date}T11:59:59`);
        return formatedDate;
    }

    /**
     * flatObject
     * @param obj objeto a reducir a un solo nivel
     * @returns objeto reducido a un solo nivel
     */
    flatObject(obj): Object {
        const flattened = {}

        Object.keys(obj).forEach((key) => {
            const value = obj[key]

            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                Object.assign(flattened, this.flatObject(value));
            } else {
                flattened[key] = value
            }
        })

        return flattened
    }
}