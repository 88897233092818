import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketsService } from '../../../../services/tickets.service';
var RecordComponent = /** @class */ (function () {
    function RecordComponent(tickets, activeModal, _historyService) {
        this.tickets = tickets;
        this.activeModal = activeModal;
        this._historyService = _historyService;
        this.subscriptions = [];
        this.historyConfig = {
            config: {
                type: 'tickets',
                base: this.tickets,
                api: 'getHistory',
                params: { history: 0 },
                order: [[0, 'desc']]
            },
            rows: [
                {
                    display: 'Estatus',
                    field: 'id'
                }
            ]
        };
        this.history = [];
    }
    RecordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.history.push(tslib_1.__assign({ ticketType: 'created' }, this.data));
        if (this.data.status === 'closed') {
            var nData = JSON.parse(JSON.stringify(this.data));
            this.history.push(tslib_1.__assign({ ticketType: 'closed' }, nData, { created_at: this.data.updated_at }));
        }
        this.subscriptions.push(this._historyService.getHistory(this.data.id_tickets).subscribe(function (data) {
            data.forEach(function (element, index) {
                var ticketType = '';
                switch (index) {
                    case 0:
                        ticketType = 'assignations';
                        break;
                    case 1:
                        ticketType = 'completions';
                        break;
                    case 2:
                        ticketType = 'confirmations';
                        break;
                    case 3:
                        ticketType = 'unrealizations';
                        break;
                }
                element.response.forEach(function (itm) {
                    _this.history.push(tslib_1.__assign({ ticketType: ticketType }, itm));
                });
                _this.sortData();
            });
        }));
    };
    RecordComponent.prototype.sortData = function () {
        return this.history.sort(function (a, b) {
            var nA = a.created_at;
            var nB = b.created_at;
            if (a.ticketType === 'closed') {
                nA = a.updated_at;
            }
            if (b.ticketType === 'closed') {
                nB = b.updated_at;
            }
            return new Date(nB) - new Date(nA);
        });
    };
    RecordComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    return RecordComponent;
}());
export { RecordComponent };
