// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableModule } from '../datatable/datatable.module';
import { FormModule } from '../form/form.module';
// Components
import { HistoryComponent } from './history.component';
// Services
import { AssignationsComponent } from './items/tickets/fields/assignations/assignations.component';
import { CompletionsComponent } from './items/tickets/fields/completions/completions.component';
import { ItemsComponent } from './items/items.component';
import { TicketsComponent } from './items/tickets/tickets.component';
import { ConfirmationsComponent } from './items/tickets/fields/confirmations/confirmations.component';
import { ClientComponent } from './items/client/client.component';
import { CorrectionsComponent } from './items/client/fields/corrections/corrections.component';
import { BadStatusComponent } from './items/client/fields/bad-status/bad-status.component';
import { NotificationsComponent } from './items/client/fields/notifications/notifications.component';
import { ClientHistoryChangeComponent } from './items/client/fields/client-history-change/client-history-change.component';
import { CreatedComponent } from './items/tickets/fields/created/created.component';
import { BadgeComponent } from './items/tickets/fields/badge/badge.component';
import { ClosedComponent } from './items/tickets/fields/closed/closed.component';
import { PipesModule } from '../pipes/pipes.module';
import { UnrealizationsComponent } from './items/tickets/fields/unrealizations/unrealizations.component';
import { SuppliesComponent } from './items/supplies/supplies.component';
import { InputsComponent } from './items/supplies/fields/inputs/inputs.component';
import { OutputsComponent } from './items/supplies/fields/outputs/outputs.component';

// ng g c "nombre del componente" --spec=false
// ng g module "nombre del module"
@NgModule({
  declarations: [
    HistoryComponent,
    AssignationsComponent,
    CompletionsComponent,
    ItemsComponent,
    TicketsComponent,
    ConfirmationsComponent,
    ClientComponent,
    CorrectionsComponent,
    BadStatusComponent,
    NotificationsComponent,
    ClientHistoryChangeComponent,
    CreatedComponent,
    BadgeComponent,
    ClosedComponent,
    UnrealizationsComponent,
    SuppliesComponent,
    InputsComponent,
    OutputsComponent
  ],
  imports: [
    CommonModule,
    DatatableModule,
    FormModule,
    PipesModule
  ],
  entryComponents: [
    HistoryComponent
  ],
  exports: [
    HistoryComponent
  ],
  providers: [],
  bootstrap: []
})
export class HistoryModule { }
